import {
    ACT_DELETE_MESSAGE,
    ACT_GET_COMMON_CODE_LIST,
    ACT_GET_MESSAGE_LIST,
    ACT_GET_MESSAGE_RECEIVE_COUNT, ACT_GET_SHOP_MALL_COMMON_CODE_LIST,
    ACT_INSERT_MESSAGE,
    ACT_SET_MESSAGE_INTERVAL, ACT_UPDATE_COMMON_INQ,
    ACT_UPDATE_READG_MESSAGE,
    ACT_UPLOAD_COMMON,
    ACT_GET_SEARCH_LIST,
    ACT_GET_PRHB_WRD_LIST,
    ACT_INSERT_CLICK_COUNT,
} from '../_act_consts';

import {
    MUT_CLOSE_ALERT,
    MUT_CLOSE_CONFIRM,
    MUT_CLOSE_PROMPT,
    MUT_CLOSE_CONNECT,
    MUT_CLOSE_CONNECT_VIEW, MUT_CLOSE_SEARCH_MODAL,
    MUT_END_LOADING,
    MUT_REMOVE_MESSAGE_COUNT,
    MUT_REMOVE_MESSAGE_INTERVAL, MUT_SET_COMMON_CODE_ITEMS,
    MUT_SET_RETURN_ROUTE, MUT_SHOW_ADDRESS_MODAL,
    MUT_SHOW_ALERT,
    MUT_SHOW_CONFIRM,
    MUT_SHOW_PROMPT,
    MUT_SHOW_CONNECT,
    MUT_SHOW_CONNECT_VIEW, MUT_SHOW_SEARCH_MODAL,
    MUT_START_LOADING,
    MUT_SET_HEADER_STICKY,
    MUT_SET_TABLE_MODAL_PAGING,
    MUT_SET_TABLE_MODAL_ITEMS,
} from '../_mut_consts';
import ApiService from '../../assets/js/api.service';
import {getItem, lengthCheck} from '../../assets/js/utils';
import navigationUtils from '../../assets/js/navigationUtils';

const state = {
    alert: {
        show: false,
        icon: '', // 추후에 추가 되면 적용
        title: '',
        subText: '',
        confirmButtonText: '확인',
        yesfunc: null,
        rightNow: false,
        adjust: false,
        html: false,
        styleObj: {},
        bodyClass: null,
        enterAble: false
    },
    confirm: {
        show: false,
        icon: '', // 추후에 추가 되면 적용
        title: '',
        text: '',
        showCancelButton: false,
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        yesfunc: null,
        nofunc: null,
        rightNow: false,
        html: false,
        adjust: false,
        styleObj: {},
    },
    customPrompt: {
        show: false,
        icon: '', // 추후에 추가 되면 적용
        title: '',
        text: '',
        showCancelButton: false,
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        yesfunc: null,
        nofunc: null,
        rightNow: false,
        html: false,
        adjust: false,
        styleObj: {},
    },
    connect: {
        show: false,
        snderUserId: 0,
        relMsgId: 0,
        rcvrUserId: 0,
        rltdTblKey: 0,
        msgCnView:''
    },
    connectView: {
        show: false,
        msgId: 0,
        userId: 0,
        userNm: '',
        imgPtUrl: '',
        regDt: '',
        readgYn: 'N',
        readgFunc: null,
        deleteMsgFunc: null
    },
    address: {
        show: false,
        okFunc: null,
        cancelFunc: null,
    },
    tableModal: {
        show: false,
        contents: '',
        isSearch: false,
        isPagination: false,
        headers: [],
        items: [],
        pageNo: 1,
        pageSize: 10,
        totalCount: 0,
        // arg 1개 : 1. pageNo
        pageFunc: null,
        // arg 1개 : 1. item
        selectFunc: null,
        // arg 2개 : 1. keyword
        searchFunc: null,
        styleObj: {},

        buttons: [],
    },
        clickcnt: {
      show: false,
      clickTrgtTyp: '',
      clickTrgtVal: '',
    },
    isSearch: false,
    isLoading: false,
    returnRoute: null,
    msgCount: 0,
    commonCode: [],
    isMobile: !!navigationUtils.any(),
    headerSticky: false
}

const getters =  {
    alert (state) {
        return state.alert;
    },
    confirm (state) {
        return state.confirm;
    },
    customPrompt (state) {
        return state.customPrompt;
    },
    connect (state) {
        return state.connect;
    },
    returnRoute (state) {
        return state.returnRoute;
    },
    connectView (state) {
        return state.connectView;
    },
    msgCount(state){
        return state.msgCount;
    },
    commonCode (state) {
        return state.commonCode;
    },
    address (state) {
        return state.address;
    },
    isMobile(state){
        return state.isMobile;
    },
    isLoading(state){
        return state.isLoading;
    },
    isSearch(state){
        return state.isSearch;
    },
    headerSticky(state){
        return state.headerSticky;
    },
    clickTrgtTyp(state){
      return state.clickTrgtTyp;
    },
    clickTrgtVal(state){
      return state.clickTrgtVal;
    }
}

const mutations = {
    [MUT_SHOW_ALERT] (state, alert) {
        alert.show = true;
        if(!alert.confirmButtonText) alert.confirmButtonText = '확인';
        if(!alert.styleObj) alert.styleObj = {};
        if(alert.adjust) alert.styleObj['margin-top'] = `${(window.outerHeight - 350)/2 + window.scrollY}px`;
        state.alert = alert;
    },
    [MUT_SHOW_CONFIRM] (state, confirm) {
        confirm.show = true;
        if(!confirm.confirmButtonText) confirm.confirmButtonText = '확인';
        if(!confirm.cancelButtonText) confirm.cancelButtonText = '취소';
        confirm.showCancelButton = (!!confirm.showCancelButton);
        confirm.styleObj = {};
        if(confirm.adjust) confirm.styleObj['margin-top'] = `${(window.outerHeight - 350)/2 + window.scrollY}px`;
        state.confirm = confirm;
    },
    [MUT_SHOW_PROMPT] (state, customPrompt) {
        customPrompt.show = true;
        if(!customPrompt.confirmButtonText) customPrompt.confirmButtonText = '확인';
        if(!customPrompt.cancelButtonText) customPrompt.cancelButtonText = '취소';
        customPrompt.showCancelButton = (!!customPrompt.showCancelButton);
        customPrompt.styleObj = {};
        if(customPrompt.adjust) customPrompt.styleObj['margin-top'] = `${(window.outerHeight - 350)/2 + window.scrollY}px`;
        state.customPrompt = customPrompt;
    },
    [MUT_CLOSE_ALERT] (state) {
        state.alert.show = false;
    },
    [MUT_CLOSE_CONFIRM] (state) {
        state.confirm.show = false;
    },
    [MUT_CLOSE_PROMPT] (state) {
        state.customPrompt.show = false;
    },
    [MUT_START_LOADING] (state) {
        state.isLoading = true;
    },
    [MUT_END_LOADING] (state) {
        state.isLoading = false;
    },
    [MUT_SHOW_CONNECT] (state, connect) {
        connect.show = true;
        connect.relMsgId = connect.relMsgId || 0;
        connect.rcvrUserId = connect.rcvrUserId || 0;
        connect.rltdTblNo = connect.rltdTblNo || 0;
        connect.rltdTblKey = connect.rltdTblKey || 0;
        connect.msgCn = connect.msgCn || ''
        state.connect = connect;
    },
    [MUT_CLOSE_CONNECT] (state) {
        state.connect.show = false;
        state.connect.rcvrUserId = 0;
        state.connect.rltdTblNo = 0;
        state.connect.rltdTblKey = 0;
        state.connect.msgCn = '';
    },
    [MUT_SET_RETURN_ROUTE] (state, returnRoute) {
        state.returnRoute = returnRoute;
    },
    [MUT_SHOW_CONNECT_VIEW] (state, connectView) {
        connectView.show = true;
        connectView.msgId = connectView.msgId || 0;
        connectView.userId = connectView.userId || 0;
        connectView.msgCn = connectView.msgCn || '';
        connectView.userNm = connectView.userNm || '';
        connectView.imgPtUrl = connectView.imgPtUrl || '';
        connectView.regDt = connectView.regDt || '';
        connectView.readgYn = connectView.readgYn || 'N';
        connectView.mngrYn = connectView.mngrYn || 'N';
        connectView.userPattnCd = connectView.userPattnCd || '';

        state.connectView = connectView;
    },
    [MUT_CLOSE_CONNECT_VIEW] (state) {
        state.connectView.show = false;
        state.connectView.msgId = 0;
    },
    [MUT_SET_COMMON_CODE_ITEMS] (state, items){
        state.commonCode = items;
    },
    [MUT_SHOW_ADDRESS_MODAL] (state, address) {
        address.show = true;
        address.styleObj = {};
        if(address.adjust) address.styleObj['margin-top'] = `${(window.outerHeight - 350)/2 + window.scrollY}px`;
        state.address = address;
    },
    [MUT_REMOVE_MESSAGE_INTERVAL](state){
        if(state.msgInterval) clearInterval(state.msgInterval);
        state.msgInterval = null;
        state.msgCount = 0;
    },
    [MUT_REMOVE_MESSAGE_COUNT](state){
        state.msgCount = 0;
    },
    [MUT_SHOW_SEARCH_MODAL](state){
        state.isSearch = true;
    },
    [MUT_CLOSE_SEARCH_MODAL](state){
        state.isSearch = false;
    },
    [MUT_SET_HEADER_STICKY](state, isSticky){
        state.headerSticky = isSticky;
    },
    [MUT_SET_TABLE_MODAL_PAGING] (state, {pageNo, pageSize, totalCount}) {
        state.tableModal.pageNo = pageNo || 0;
        state.tableModal.pageSize = pageSize || 0;
        state.tableModal.totalCount = totalCount || 0;
    },
    [MUT_SET_TABLE_MODAL_ITEMS] (state, items) {
        state.tableModal.items = items;
    },
    //     [CLICK_TRGT](state, clickTrgtTyp,clickTrgtVal) {
//         state.clickTrgtTyp = clickTrgtTyp;
//         state.clickTrgtVal = clickTrgtVal;
//     },
}

const actions = {
    [ACT_GET_COMMON_CODE_LIST]({}, params){
        return new Promise(resolve => {
            ApiService.query('/v1/app/common/dcd', params).then(response => resolve(response));
        });
    },
    [ACT_UPLOAD_COMMON]({}, {menuType, contentsType, fileList}){
        return ApiService.upload(`/v1/app/common/${menuType}/upload/${contentsType}`, fileList);
    },
    [ACT_GET_MESSAGE_LIST]({}, params){
        return new Promise(resolve => {
            ApiService.query('/v1/app/common/msgs', params, false).then(response => resolve(response));
        });
    },
    [ACT_GET_MESSAGE_RECEIVE_COUNT]({}){
        return new Promise(resolve => {
            ApiService.query('/v1/app/common/msg/rcvr', {}).then(response => resolve(response));
        });
    },
    [ACT_INSERT_MESSAGE] ({}, params){
        return new Promise(resolve => {
            ApiService.post('/v1/app/common/msg', params, true).then(response => resolve(response));
        });
    },
    [ACT_UPDATE_READG_MESSAGE]({}, {msgId, params}){
        return new Promise(resolve => {
            ApiService.put(`/v1/app/common/msg/${msgId}/readg`, params, true).then(response => resolve(response));
        });
    },
    [ACT_DELETE_MESSAGE]({}, msgId){
        return new Promise(resolve => {
            ApiService.delete(`/v1/app/common/msg`, msgId, true).then(response => resolve(response));
        });
    },
    [ACT_UPDATE_COMMON_INQ]({}, {contentsId, contentsType}){
        ApiService.put(`/v1/app/common/contents/${contentsId}/${contentsType}`).then();
        // return new Promise(resolve => {
        //     ApiService.put(`/v1/app/common/contents/${contentsId}/${contentsType}`).then(response => resolve(response));
        // });
    },
    [ACT_GET_SHOP_MALL_COMMON_CODE_LIST]({}, params){
        return new Promise(resolve => {
            ApiService.query('/v1/app/common/cd', params).then(response => resolve(response));
        });
    },
    [ACT_SET_MESSAGE_INTERVAL](context){
        getMessageCount(context);
        if(context.state.msgInterval) clearInterval(context.state.msgInterval);
        // context.state.msgInterval = setInterval(() => {
        //     getMessageCount(context);
        // }, 1000*30);

        // }, 1000*5);
    },
    [ACT_GET_SEARCH_LIST]({}, params){
        return new Promise(resolve => {
            ApiService.query(`/v1/app/common/search`, params).then(response => resolve(response));
        });
    },
    [ACT_GET_PRHB_WRD_LIST]({}, params){
        return new Promise(resolve => {
            ApiService.query(`/v1/app/common/prhbWrd`, params).then(response => resolve(response));
        });
    },
    [ACT_INSERT_CLICK_COUNT]({}, params){
        // alert(params.clickTrgtVal + params.clickTrgtTyp)
        return new Promise(resolve => {
            ApiService.put(`/v1/app/common/potal/update/click`, params).then(response => resolve(response));
        });
    },
}

function getMessageCount(context){
    context.dispatch(ACT_GET_MESSAGE_RECEIVE_COUNT)
    .then(res => {
        if(lengthCheck(res)){
            const item = getItem(res);
            if(item.rcvrMsgCnt > 0) context.state.msgCount = item.rcvrMsgCnt;
            setTimeout(() => {context.state.msgCount = 0;}, 1000*8);
            // setTimeout(() => {context.state.msgCount = 0;}, 1000*2);
        }else{
            context.commit(MUT_REMOVE_MESSAGE_INTERVAL);
        }
    })
    .catch(e => {
        console.error(e);
        context.commit(MUT_REMOVE_MESSAGE_INTERVAL);
    });
}


export const common = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,

}
