import {ACT_INSERT_CLICK_COUNT} from '../_act_consts';
// import { CLICK_TRGT } from '../_mut_consts';
import ApiService from '../../assets/js/api.service';

// const state = {
//     clickcnt: {
//       show: false,
//       clickTrgtTyp: '',
//       clickTrgtVal: '',
//     }
//   }
//   const getters = {
//     //ctgrItems: state => state.ctgrItems,
//     clickTrgtTyp(state){
//       return state.clickTrgtTyp;
//     },
//     clickTrgtVal(state){
//       return state.clickTrgtVal;
//     },
//   }
//   const mutations = {
//     [CLICK_TRGT](state, clickTrgtTyp,clickTrgtVal) {
//         state.clickTrgtTyp = clickTrgtTyp;
//         state.clickTrgtVal = clickTrgtVal;
//     },
//   }
const state = {}

const getters = {}

const mutations = {}
const actions = {
  [ACT_INSERT_CLICK_COUNT]({}, params){
    alert('actions')
    return new Promise(resolve => {
        ApiService.post('/v1/app/common/potal/update/click', params).then(response => resolve(response));
    });
},
}

export const clickcnt = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
