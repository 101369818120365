import ApiService from '../../assets/js/api.service';
import {
	ACT_GET_AI_CHAT_BOT_SEND_MSG,
} from '../_act_consts';

const state = {}

const getters = {}

const mutations = {}

const actions = {
	[ACT_GET_AI_CHAT_BOT_SEND_MSG]({ }, params) {
		return new Promise(resolve => {
			ApiService.post('/v1/app/ai/chatbot/send', params).then(response => resolve(response));
		});
	},
}

export const aichatbot = {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}