import Vue from 'vue'
import Vuex from 'vuex'
import { common } from './modules/common'
import { auth } from './modules/auth'
import { main } from './modules/main'
import { community } from './modules/community'
import { information } from './modules/information'
import { promotion } from './modules/promotion'
import { studio } from './modules/studio'
import { support } from './modules/support'
import { commerce } from './modules/commerce';
import { survey } from './modules/survey';
import {education} from './modules/education';
import {notice} from './modules/notice';
import {notice2} from './modules/notice2';
import {broadcast} from './modules/broadcast';
import {video} from './modules/video';  //홍보동영상 모달
import {scommunity} from './modules/scommunity';  //소상공인 커뮤니티
import {mypage2} from './modules/mypage2';  //리뉴얼 마이페이지
import { sbptn } from './modules/sbptn'; 
import { vod } from './modules/vod'; 
import { subsecnmy } from './modules/subsecnmy'; 
import { privacy } from './modules/privacy'; 
import {join} from './modules/join'; // 리뉴얼 회원가입
import { econo } from './modules/econo';
import { sentcont } from './modules/sentcont';
import { space } from './modules/space';
import { diagn } from './modules/diagn';
import { aichatbot } from './modules/aichatbot';
import { clickcnt } from './modules/clickcnt'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    common,
    auth,
    main,
    community,
    information,
    promotion,
    studio,
    support,
    commerce,
    survey,
    education,
    notice,
    notice2,
    broadcast,
    video,
    scommunity,
    mypage2,
    sbptn,
    vod,
    subsecnmy,
    privacy,
    join,
    econo,
    sentcont,
    space,
    diagn,
    aichatbot,
    clickcnt,
  }
})
